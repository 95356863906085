import { useMemo, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import BrandIcon from "../icons/BrandIcon";
import CalendarIcon from "../ui/icons/CalendarIcon";
import FireIcon from "../ui/icons/FireIcon";
import StarIcon from "../ui/icons/StarIcon";

const DashboardContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useDashboardContextVals = () => {
  const [data, setData] = useState({});
  const [achievementsOpen, setAchievementsOpen] = useState(false);

  const openAchievements = () => {
    setAchievementsOpen(true);
  };

  const closeAchievements = () => {
    setAchievementsOpen(false);
  };

  const goals = useMemo(() => {
    return [
      {
        title: "Forks",
        key: "fork",
        number: 5,
        comparison: data.numForkedRecipes + data.numForkedCollections,
        comparitor: ">=",
        show: data.numForkedCollections + data.numForkedRecipes < 30,
        description: "Recipes + Collections",
        icon: <BrandIcon className="h-full w-full" />,
        levels: [
          {
            title: "New to Forking",
            number: 20,
            description: "Fork 20 recipes or collections",
          },
          {
            title: "Experienced Forker",
            number: 40,
            description: "Fork 60 recipes or collections",
          },
          {
            title: "Master Forker",
            number: 80,
            description: "Fork 140 recipes or collections",
          },
        ],
      },
      {
        title: "Plans",
        key: "plan",
        number: 3,
        comparison: data.upcomingMeals?.length,
        comparitor: ">=",
        icon: <CalendarIcon className="h-full w-full text-gray-600" />,
        description: "Recipes planned for next 7 days",
        levels: [
          {
            title: "Easy Going Planner",
            number: 3,
            description: "Plan 3 meals",
          },
          {
            title: "Average Planner",
            number: 3,
            description: "Plan 6 meals",
          },
          {
            title: "Power Planner",
            number: 3,
            description: "Plan 9+ meals",
          },
        ],
      },
      {
        title: "Cooked",
        key: "cooked",
        number: 3,
        comparison: data.numMealsMade,
        description: "Recipes made in the last 7 days",
        comparitor: ">=",
        show: true,
        icon: <FireIcon className="h-full w-full text-gray-600" />,
        levels: [
          {
            title: "Takeout King",
            number: 3,
            description: "Cook 3 meals",
          },
          {
            title: "Regular Cook",
            number: 3,
            description: "Cook 6 meals",
          },
          {
            title: "Seasoned Chef",
            number: 3,
            description: "Cook 9+ meals",
          },
        ],
      },
      {
        title: "Reviews",
        key: "review",
        number: 3,
        comparison: data.numReviews,
        description: "Reviews Written",
        comparitor: ">=",
        show: data.numReviews < 6,
        icon: <StarIcon className="h-full w-full text-gray-600" />,
        levels: [
          {
            title: "New to Reviews",
            number: 3,
            description: "Review 3 recipes or collections",
          },
          {
            title: "Average Reviewer",
            number: 3,
            description: "Review 6 recipes or collections",
          },
          {
            title: "Connoisseur",
            number: 3,
            description: "Review 9+ recipes or collections",
          },
        ],
      },
    ];
  }, [data]);

  const clearDashboard = () => {
    setData({});
  };

  return {
    clearDashboard,
    data,
    setData,
    goals,
    openAchievements,
    closeAchievements,
    achievementsOpen,
  };
};

const DashboardContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const dashboardContext = useDashboardContextVals(props.defaults);

  return <DashboardContext.Provider value={{ ...dashboardContext }}>{props.children}</DashboardContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useDashboardContext = () => useContext(DashboardContext);
const useDashboardSelectorContext = (selector: any) => {
  return useContextSelector(DashboardContext, selector);
};
export { DashboardContextProvider, useDashboardContext, useDashboardSelectorContext };
